
import { Typography } from '@mui/material'
import * as React from 'react'

const OneMarkPage = () => {
  return (
    <>
      <Typography className="main-header">
        One Mark Bids
      </Typography>
      <Typography className="paragraph">
        The one-mark variant applies only when in the scenario of a forced bid. When the one-mark bid is called, the
        bidding team must win every trick in the hand. The bidder may also call one-mark nil if the ruleset allows.
      </Typography>
    </>
  )
}

export default OneMarkPage
