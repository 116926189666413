
import { Typography } from '@mui/material'

const AcknowledgementsPage = () => {
  return (
    <>
      <Typography className="main-header">Acknowledgements</Typography>
      <Typography className="paragraph">
        Contributors:
      </Typography>
      <ul>
        <li><Typography className="paragraph">Aidan Hill &apos;23 - Writer</Typography></li>
        <li><Typography className="paragraph">Owen Dunston &apos;23 - Writer</Typography></li>
        <li><Typography className="paragraph">Joshua Wood &apos;23 - Editor, Styling/Illustrations</Typography></li>
      </ul>
    </>
  )
}

export default AcknowledgementsPage
