
import { Typography } from '@mui/material'

const NilDoublesHighPage = () => {
  return (
    <>
      <Typography className="main-header">
        Doubles are High
      </Typography>
      <Typography className="paragraph">
        When a player declares nil with doubles high, the double is the highest domino in its suit. This is often called
        when the nil-bidding player has no doubles in their hand.
      </Typography>
    </>
  )
}

export default NilDoublesHighPage
